<template>
  <li :class="classObject">
    <img class="partners-card__logo" :src="$mediaWp(content, 'partners')" alt="" />
  </li>
</template>

<script>
export default {
  props: ["parentClass", "swiper", "content"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["partners-card"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.partners-card__logo {
  width: 210px;
  height: 94px;

  cursor: pointer;
}

@media (max-width: 1599.98px) {
  .partners-card__logo {
    width: 154px;
    height: 68px;
  }
}

@media (max-width: 1199.98px) {
  .partners-card__logo {
    width: 127px;
    height: 56px;
  }
}

@media (max-width: 991.98px) {
  .partners-card__logo {
    width: 130px;
    height: 56px;
  }
}

@media (max-width: 767.98px) {
  .partners-card__logo {
    width: 135px;
    height: 59px;
  }
}
</style>