<template>
  <section id="partners" class="partners">
    <div class="partners__swiper swiper">
      <ul class="partners__list swiper-wrapper">
        <ItemPartners swiper="true" v-for="item in content.partners_list" :key="item" :content="item" />
      </ul>
    </div>
  </section>
</template>

<script>
import ItemPartners from "@/components/block/ItemPartnersApp.vue";
import Swiper, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

export default {
  components: {
    ItemPartners,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.partners,
    };
  },
  mounted() {
    new Swiper(".partners__swiper", {
      modules: [Autoplay],
      slidesPerView: 1.5,
      loop: true,
      lazy: true,
      speed: 6000,
      loopFillGroupWithBlank: true,
      autoplay: {
        enabled: true,
        delay: 1,
        reverseDirection: true,
      },
      breakpoints: {
        425: {
          slidesPerView: 2.5,
          spaceBetween: 60,
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 70,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 83,
        },
        2000: {
          slidesPerView: 7,
          spaceBetween: 150,
        },
      },
    });
  },
};
</script>

<style>
.partners {
  margin-bottom: 150px;
}

.partners__list {
  align-items: center;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

@media (max-width: 1599.98px) {
  .partners {
    margin-bottom: 150px;
  }
}

@media (max-width: 1199.98px) {
  .partners {
    margin-bottom: 130px;
  }
}

@media (max-width: 991.98px) {
  .partners {
    margin-bottom: 110px;
  }
}

@media (max-width: 767.98px) {
  .partners {
    margin-bottom: 90px;
  }
}
</style>